<ng-container>
  <div class="table-parameters__table">
    <div class="table-parameters__body main-table" *ngIf="isForwadSearchMode">
      <p-table [value]="forwardSearchData" [columns]="columns" dataKey="externalProductionOrderId" [responsive]="true" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="table-icon-th"></th>
            <ng-container *ngFor="let column of columns">
              <th [style.width]="column.width" [style.textAlign]="column.textAlign">{{ column.label | translate }}</th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-expanded="expanded">
          <tr>
            <td class="table-icon-th">
              <a href="#" [pRowToggler]="rowData" *ngIf="rowData?.forwardTraceContainers?.length">
                <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'" class="table-parameters__exp-col-icon"></i>
              </a>
            </td>
            <td class="table-text">
              {{ rowData.externalProductionOrderId }}
            </td>
            <td class="table-text">
              {{ rowData.articleName }}
            </td>
            <td class="table-text">{{ rowData.externalArticleId }} / {{ rowData.configurationId }}</td>
            <td class="table-text text-right">
              {{ rowData.deviationPercentage | number: '1.0-2' }} %
            </td>
            <td class="table-text">
              {{ rowData.externalWorkCenterId }}
            </td>
            <td class="table-text">{{ rowData.status }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
          <tr *ngIf="rowData?.forwardTraceContainers?.length">
            <td [attr.colspan]="columns.length + 1" class="first-level">
              <lib-production-material-list
                [selectedSearchMode]="selectedSearchMode"
                [traceabilityItem]="rowData.forwardTraceContainers"
              ></lib-production-material-list>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <br />
    </div>
    <div class="table-parameters__body" *ngIf="!isForwadSearchMode">
      <p-table [value]="backwardSearchData" [columns]="columns" dataKey="externalProductionOrderId" [responsive]="true">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="table-icon-th"></th>
            <ng-container *ngFor="let column of columns">
              <th [style.width]="column.width" [style.textAlign]="column.textAlign">{{ column.label | translate }}</th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-expanded="expanded">
          <tr>
            <td class="table-icon-th">
              <a href="#" [pRowToggler]="rowData" *ngIf="rowData?.mountedBatches?.length">
                <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'" class="table-parameters__exp-col-icon"></i>
              </a>
            </td>
            <td class="table-text">{{ rowData.externalProductionOrderId }}</td>
            <td class="table-text">
              {{ rowData.externalArticleId }} <br/>
              {{ rowData.configurationId }}
            </td>
            <td class="table-text">
              {{ rowData.articleName }} <br/>
              {{ rowData.configurationName }}
            </td>
            <td class="table-text td-padding">
              <span class="primary-material__icon">
                <i class="icon icon-pallet"></i>
                <span class="value pallet-value"> {{ rowData.itemCount }}</span>
              </span>
            </td>
            <td class="table-text text-right">
              {{ rowData.deviationPercentage | number: '1.0-2' }} %
            </td>
            <td class="table-text"> {{ rowData.externalWorkCenterId }} </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
          <tr *ngIf="rowData?.mountedBatches?.length">
            <td [attr.colspan]="columns.length + 1" class="first-level">
              <lib-order-batch-list [traceabilityItem]="rowData.mountedBatches"></lib-order-batch-list>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</ng-container>
