import { finalize } from 'rxjs/operators';
import { BackwardTraceBomLine, ForwardTraceOrder } from 'chronos-core-client';
import { Component, OnInit } from '@angular/core';
import moment from 'moment-mini';
import { SelectItem } from 'primeng/api';
import { TraceabilitySearchModeOption } from '../../models/traceability.model';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TraceabilityMainService } from '../../services/traceability-main.service';

@Component({
  selector: 'lib-traceability',
  templateUrl: './traceability.component.html',
  styleUrls: ['./traceability.component.scss']
})
export class TraceabilityComponent implements OnInit {
  public traceabilitySearchModeOption: SelectItem[] = [];
  public selectedSearchMode: SelectItem;
  public searchStartDateTime: Date;
  public searchEndDateTime: Date;
  public textQuery? = '';
  public searchPlaceHolder = '';
  public maxDate: Date;
  public isNodeLoadingSubject = new BehaviorSubject<boolean>(false);
  public isNodeLoading$ = this.isNodeLoadingSubject.asObservable();
  public searchCode: string;
  public searchPeriod: string;
  public forwardSearchData: ForwardTraceOrder[];
  public backwardSearchData: BackwardTraceBomLine[];
  public showTables = false;
  public textInput = '';
  isSubmitDisabled = true;
  isExportDisabled = true;
  translatedMessage = 'TRACEABILITY.NO_DATA_LOADED';

  constructor(private translateService: TranslateService,
    private traceabilityMainService: TraceabilityMainService) {
      this.traceabilitySearchModeOption = [];
    }

  public ngOnInit(): void {
    this.searchPlaceHolder = 'TRACEABILITY.FORWARD_SEARCH_PLACEHOLDER';
    this.searchStartDateTime = moment(new Date()).subtract(1, 'month').toDate();
    this.searchEndDateTime = this.maxDate = new Date();

    this.translateService.stream(['TRACEABILITY.FORWARD_SEARCH', 'TRACEABILITY.BACKWARD_SEARCH']).subscribe((values) => {
      Object.keys(values).map((key) => {
        switch (key) {
          case 'TRACEABILITY.FORWARD_SEARCH':
            if(values[key] !== 'TRACEABILITY.FORWARD_SEARCH') {
              this.traceabilitySearchModeOption.push({ label: values[key], value: TraceabilitySearchModeOption.ForwardSearch });
            }
            break;
          case 'TRACEABILITY.BACKWARD_SEARCH':
            if(values[key] !== 'TRACEABILITY.BACKWARD_SEARCH') {
             this.traceabilitySearchModeOption.push({ label: values[key], value: TraceabilitySearchModeOption.BackwardSearch });
            }
            break;
          default:
            break;
        }
      });

      if(this.traceabilitySearchModeOption.length > 0 && !this.selectedSearchMode) {
        console.info('this.traceabilitySearchModeOption', this.traceabilitySearchModeOption);
        this.selectedSearchMode = this.traceabilitySearchModeOption[0].value;
      }

    });
  }

  public onFilterChange(): void {
    this.searchPlaceHolder =
      this.selectedSearchMode === this.traceabilitySearchModeOption[0].value
        ? 'TRACEABILITY.FORWARD_SEARCH_PLACEHOLDER'
        : 'TRACEABILITY.BACKWARD_SEARCH_PLACEHOLDER';

    if (this.textQuery && this.textQuery.length > 0 && this.selectedSearchMode) {
      this.isSubmitDisabled = false;
    } else {
      this.isSubmitDisabled = true;
    }
  }

  public onStartDateChange(): void {
    const searchStartDateTime = this.searchStartDateTime > this.searchEndDateTime ? this.searchEndDateTime : this.searchStartDateTime;
    this.searchStartDateTime = moment(searchStartDateTime).startOf('day').toDate();
    console.info('searchStartTime:', this.searchStartDateTime);
  }

  public onEndDateChange(): void {
    const searchEndDateTime =
      this.searchStartDateTime < this.searchEndDateTime ? (this.searchEndDateTime > this.maxDate
        ? this.maxDate : this.searchEndDateTime) : this.searchStartDateTime;
    this.searchEndDateTime = moment(searchEndDateTime).startOf('day').toDate();
    console.info('searchEndTime:', this.searchEndDateTime);
  }

  public submitFilters(): void {
    this.showTables = false;
    this.showLoader();
    const { startTime, endTime } = this.normalizeStartAndIncrementEndDate();
    if (this.selectedSearchMode === this.traceabilitySearchModeOption[0].value) {
      this.traceabilityMainService
        .getTraceabilityForwardSearch(startTime.toISOString(), this.textQuery, endTime.toISOString())
        .pipe(finalize(() => {
          this.hideLoader();
        }))
        .subscribe(
          (data) => {
            if (data && data.length > 0) {
              this.forwardSearchData = data;
              this.showTables = true;
              this.isExportDisabled = false;
              this.textInput = null;
            } else {
              this.isExportDisabled = true;
              this.translatedMessage = 'TRACEABILITY.NO_DATA_FOUND';
              this.textInput = this.textQuery;
            }
          },
          (error) => {
            console.info(error);
          }
        );
    } else {
      this.traceabilityMainService
        .getTraceabilityBackwardSearch(startTime.toISOString(), this.textQuery, endTime.toISOString())
        .pipe(finalize(() => {
          this.hideLoader();
        }))
        .subscribe(
          (data) => {
            if (data && data.length > 0) {
              this.backwardSearchData = data;
              this.showTables = true;
              this.isExportDisabled = false;
              this.textInput = null;
            } else {
              this.isExportDisabled = true;
              this.translatedMessage = 'TRACEABILITY.NO_DATA_FOUND';
              this.textInput = this.textQuery;
            }
          },
          (error) => {
            console.info(error);
          }
        );
    }
  }

  public export(): void {
    this.showLoader();
    if (this.selectedSearchMode === this.traceabilitySearchModeOption[0].value) {
      this.traceabilityMainService
        .getTraceabilityForwardSearchExport(this.searchStartDateTime.toISOString(), this.textQuery, this.searchEndDateTime.toISOString())
        .pipe(finalize(() => {
          this.hideLoader();
        }))
        .subscribe((data) => {
          if (data) {
            this.downloadCSV(data.csvContent);
          }
        }, (error) => {
          console.info(error);
        });
    } else {
      this.traceabilityMainService
        .getTraceabilityBackwardSearchExport(this.searchStartDateTime.toISOString(), this.textQuery, this.searchEndDateTime.toISOString())
        .pipe(finalize(() => {
          this.hideLoader();
        }))
        .subscribe((data) => {
          if (data) {
            this.downloadCSV(data.csvContent);
          }
        }, (error) => {
          console.info(error);
        });
    }
  }

  private downloadCSV(csvData: string) {
    if (csvData) {
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${this.selectedSearchMode}_export_data.csv`;
      link.click();
    }
  }

  private showLoader(): void {
    this.isNodeLoadingSubject.next(true);
  }

  private hideLoader(): void {
    this.isNodeLoadingSubject.next(false);
  }

  private normalizeStartAndIncrementEndDate() {
    const startTime = moment(this.searchStartDateTime).startOf('day').toDate();
    const endTime = moment(this.searchEndDateTime).add(1, 'days').startOf('day').toDate();
    return { startTime, endTime };
  }
}
