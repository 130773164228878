/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CheckProductionOrderEntry } from '../models/check-production-order-entry';
import { UncheckProductionOrderEntry } from '../models/uncheck-production-order-entry';
import { PresetupProductionOrderEntry } from '../models/presetup-production-order-entry';
import { CancelProductionOrderPresetupEntry } from '../models/cancel-production-order-presetup-entry';
import { StartProductionOrderEntry } from '../models/start-production-order-entry';
import { StartManualProductionOrderEntry } from '../models/start-manual-production-order-entry';
import { CancelProductionOrderEntry } from '../models/cancel-production-order-entry';
import { FinishProductionOrderEntry } from '../models/finish-production-order-entry';
import { EndProductionOrderProductionEntry } from '../models/end-production-order-production-entry';
import { CancelEndProductionEntry } from '../models/cancel-end-production-entry';
import { FinishProductionOrderForManualMachineEntry } from '../models/finish-production-order-for-manual-machine-entry';
import { ProductionOrderManualModeSortingValuesEntry } from '../models/production-order-manual-mode-sorting-values-entry';
import { ProductionOrderDeviationEntry } from '../models/production-order-deviation-entry';
import { SetProductionOrderFinishInfoEntry } from '../models/set-production-order-finish-info-entry';
import { SetCurrentRunPhaseEntry } from '../models/set-current-run-phase-entry';
import { ConfirmProductionOrderKpiEntry } from '../models/confirm-production-order-kpi-entry';
import { UnconfirmProductionOrderKpiEntry } from '../models/unconfirm-production-order-kpi-entry';
import { ReopenProductionOrderEntry } from '../models/reopen-production-order-entry';
import { AddProductionOrderSetupWasteEntry } from '../models/add-production-order-setup-waste-entry';
import { DeleteProductionOrderSetupWasteEntry } from '../models/delete-production-order-setup-waste-entry';
import { EditProductionOrderSetupPhaseEntry } from '../models/edit-production-order-setup-phase-entry';
import { SetSetupEndTimeEntry } from '../models/set-setup-end-time-entry';
import { ChangeContainerTargetQuantityEntry } from '../models/change-container-target-quantity-entry';
import { ChangeContainerTargetBobbinQuantityEntry } from '../models/change-container-target-bobbin-quantity-entry';
import { PrintLabelForOuterEntry } from '../models/print-label-for-outer-entry';
import { ChangeProductionOrderOuterInfoEntry } from '../models/change-production-order-outer-info-entry';
import { ChangeOuterQuantityEntry } from '../models/change-outer-quantity-entry';
import { AddProductionOrderWasteAssignmentToOuterEntry } from '../models/add-production-order-waste-assignment-to-outer-entry';
import { DeleteProductionOrderWasteAssignmentFromOuterEntry } from '../models/delete-production-order-waste-assignment-from-outer-entry';
import { SplitBillOfMaterialRowEntry } from '../models/split-bill-of-material-row-entry';
import { ProductionOrder } from '../models/production-order';
import { ProductionOrderExternalInfo } from '../models/production-order-external-info';
import { HeaderKpi } from '../models/header-kpi';
import { ProductionOrderPredecessorInfo } from '../models/production-order-predecessor-info';
import { ProductionOrderData } from '../models/production-order-data';
import { GetProductionOrdersForTimePeriodEntry } from '../models/get-production-orders-for-time-period-entry';
import { BillOfMaterial } from '../models/bill-of-material';
import { ProductionOrderSetupPhaseState } from '../models/production-order-setup-phase-state';
import { ProductionOrderManualModeSortingValues } from '../models/production-order-manual-mode-sorting-values';
import { ProductionOrderProductionLoyalty } from '../models/production-order-production-loyalty';
import { ProductionOrderFinishedInfos } from '../models/production-order-finished-infos';
import { RunPhase } from '../models/run-phase';
import { KpiValue } from '../models/kpi-value';
import { Outer } from '../models/outer';
import { OuterPrintInfo } from '../models/outer-print-info';
import { ProjectedRunEnd } from '../models/projected-run-end';
import { DefaultTargetContainerQuantityInfo } from '../models/default-target-container-quantity-info';
import { SplitBillOfMaterialData } from '../models/split-bill-of-material-data';
@Injectable({
  providedIn: 'root'
})
class ProductionOrderService extends __BaseService {
  static readonly checkProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/Check';
  static readonly uncheckProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/Uncheck';
  static readonly presetupProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/Presetup';
  static readonly cancelProductionOrderPresetupPath = '/api/v1/ProductionOrder/{productionOrderId}/CancelPresetup';
  static readonly startProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/Start';
  static readonly startProductionOrderManualMachinePath = '/api/v1/ProductionOrder/{productionOrderId}/StartProductionOrderManualMachine';
  static readonly startProductionOrderWithAutomaticPeriodSelectionPath =
    '/api/v1/ProductionOrder/{productionOrderId}/StartWithAutomaticPeriodSelection';
  static readonly cancelProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/Cancel';
  static readonly resetProductionOrdersPath = '/api/v1/ProductionOrder/Reset';
  static readonly finishProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/Finish';
  static readonly endProductionOrderProductionPath = '/api/v1/ProductionOrder/{productionOrderId}/EndProduction';
  static readonly cancelEndProductionPath = '/api/v1/ProductionOrder/{productionOrderId}/CancelEndProduction';
  static readonly endProductionForManualMachinePath = '/api/v1/ProductionOrder/{productionOrderId}/EndProductionForManualMachine';
  static readonly saveProductionOrderManualModeSortingValuesPath =
    '/api/v1/ProductionOrder/{productionOrderId}/SaveManualModeSortingValues';
  static readonly editProductionOrderDeviationReasonPath = '/api/v1/ProductionOrder/{productionOrderId}/EditDeviationReason';
  static readonly setAsNextProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/SetNext';
  static readonly setProductionOrderFinishedInfoPath = '/api/v1/ProductionOrder/{productionOrderId}/SetFinishedInfos';
  static readonly setCurrentRunSubPhasePath = '/api/v1/ProductionOrder/{productionOrderId}/SetCurrentRunSubPhase';
  static readonly confirmProductionOrderKpiPath = '/api/v1/ProductionOrder/{productionOrderId}/ConfirmKpi';
  static readonly unconfirmProductionOrderKpiPath = '/api/v1/ProductionOrder/{productionOrderId}/UnconfirmKpi';
  static readonly reopenProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/Reopen';
  static readonly addProductionOrderSetupWastePath = '/api/v1/ProductionOrder/{productionOrderId}/AddSetupWasteToProductionOrder';
  static readonly deleteProductionOrderSetupWastePath = '/api/v1/ProductionOrder/{productionOrderId}/DeleteSetupWasteFromProductionOrder';
  static readonly editProductionOrderSetupPhasePath = '/api/v1/ProductionOrder/{productionOrderId}/EditSetupPhase';
  static readonly setSetupEndTimePath = '/api/v1/ProductionOrder/{productionOrderId}/SetSetupEndTime';
  static readonly changeContainerTargetQuantityPath = '/api/v1/ProductionOrder/{productionOrderId}/container/targetQuantity';
  static readonly changeContainerTargetBobbinQuantityPath = '/api/v1/ProductionOrder/{productionOrderId}/container/targetBobbinQuantity';
  static readonly printLabelForOuterPath = '/api/v1/ProductionOrder/{productionOrderId}/Outer/PrintLabel';
  static readonly changeProductionOrderOuterInfoPath = '/api/v1/ProductionOrder/{productionOrderId}/Outer/Info';
  static readonly changeOuterQuantityPath = '/api/v1/ProductionOrder/{productionOrderId}/Outer/ChangeOuterQuantity';
  static readonly addProductionOrderWasteAssignmentToOuterPath =
    '/api/v1/ProductionOrder/{productionOrderId}/Outer/AddWasteAssignmentToOuter';
  static readonly deleteProductionOrderWasteAssignmentFromOuterPath =
    '/api/v1/ProductionOrder/{productionOrderId}/Outer/DeleteWasteAssignmentFromOuter';
  static readonly splitBillOfMaterialRowPath = '/api/v1/ProductionOrder/{productionOrderId}/SplitBillOfMaterialRow';
  static readonly getProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}';
  static readonly getProductionOrderInfoByExternalIdPath =
    '/api/v1/ProductionOrder/{externalProductionOrderId}/GetProductionOrderInfoByExternalId';
  static readonly getHeaderKpiPath = '/api/v1/ProductionOrder/GetHeaderKpi/{workCenterId}';
  static readonly getProductionOrderPredecessorInfoPath = '/api/v1/ProductionOrder/{productionOrderId}/PredecessorInfo';
  static readonly getProductionOrderDetailsPath = '/api/v1/ProductionOrder/{workCenterId}/GetProductionOrderDetails';
  static readonly getBillOfMaterialsPath = '/api/v1/ProductionOrder/{productionOrderId}/GetBillOfMaterials';
  static readonly getSetupPhaseStatePath = '/api/v1/ProductionOrder/{productionOrderId}/GetSetupPhaseState';
  static readonly getManualModeSortingValuesPath = '/api/v1/ProductionOrder/{productionOrderId}/GetManualModeSortingValues';
  static readonly getProductionLoyaltyReasonsPath = '/api/v1/ProductionOrder/{productionOrderId}/ProductionLoyaltyReasons';
  static readonly getFinishedInfosPath = '/api/v1/ProductionOrder/{productionOrderId}/GetFinishedInfos';
  static readonly getRunPhasesPath = '/api/v1/ProductionOrder/{productionOrderId}/RunPhases';
  static readonly getAllKpisPath = '/api/v1/ProductionOrder/{productionOrderId}/GetAllKpis';
  static readonly getOuterPath = '/api/v1/ProductionOrder/{productionOrderId}/Outer';
  static readonly getPrintInfoPath = '/api/v1/ProductionOrder/{productionOrderId}/Outer/PrintInfo';
  static readonly getProjectedRunEndPath = '/api/v1/ProductionOrder/{productionOrderId}/GetProjectedRunEnd';
  static readonly getDefaultTargetContainerQuantityPath = '/api/v1/ProductionOrder/{productionOrderId}/GetDefaultTargetContainerQuantity';
  static readonly splitBillOfMaterialDataPath =
    '/api/v1/ProductionOrder/{productionOrderId}/billOfMaterials/{billOfMaterialId}/SplitBillOfMaterialData';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `ProductionOrderService.CheckProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  checkProductionOrderResponse(params: ProductionOrderService.CheckProductionOrderParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/Check`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.CheckProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  checkProductionOrder(params: ProductionOrderService.CheckProductionOrderParams): __Observable<null> {
    return this.checkProductionOrderResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.UncheckProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  uncheckProductionOrderResponse(params: ProductionOrderService.UncheckProductionOrderParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/Uncheck`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.UncheckProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  uncheckProductionOrder(params: ProductionOrderService.UncheckProductionOrderParams): __Observable<null> {
    return this.uncheckProductionOrderResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.PresetupProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  presetupProductionOrderResponse(params: ProductionOrderService.PresetupProductionOrderParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/Presetup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.PresetupProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  presetupProductionOrder(params: ProductionOrderService.PresetupProductionOrderParams): __Observable<null> {
    return this.presetupProductionOrderResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.CancelProductionOrderPresetupParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  cancelProductionOrderPresetupResponse(
    params: ProductionOrderService.CancelProductionOrderPresetupParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/CancelPresetup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.CancelProductionOrderPresetupParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  cancelProductionOrderPresetup(params: ProductionOrderService.CancelProductionOrderPresetupParams): __Observable<null> {
    return this.cancelProductionOrderPresetupResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.StartProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  startProductionOrderResponse(params: ProductionOrderService.StartProductionOrderParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/Start`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.StartProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  startProductionOrder(params: ProductionOrderService.StartProductionOrderParams): __Observable<null> {
    return this.startProductionOrderResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.StartProductionOrderManualMachineParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  startProductionOrderManualMachineResponse(
    params: ProductionOrderService.StartProductionOrderManualMachineParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/StartProductionOrderManualMachine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.StartProductionOrderManualMachineParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  startProductionOrderManualMachine(params: ProductionOrderService.StartProductionOrderManualMachineParams): __Observable<null> {
    return this.startProductionOrderManualMachineResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.StartProductionOrderWithAutomaticPeriodSelectionParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  startProductionOrderWithAutomaticPeriodSelectionResponse(
    params: ProductionOrderService.StartProductionOrderWithAutomaticPeriodSelectionParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/StartWithAutomaticPeriodSelection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.StartProductionOrderWithAutomaticPeriodSelectionParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  startProductionOrderWithAutomaticPeriodSelection(
    params: ProductionOrderService.StartProductionOrderWithAutomaticPeriodSelectionParams
  ): __Observable<null> {
    return this.startProductionOrderWithAutomaticPeriodSelectionResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.CancelProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  cancelProductionOrderResponse(params: ProductionOrderService.CancelProductionOrderParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/Cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.CancelProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  cancelProductionOrder(params: ProductionOrderService.CancelProductionOrderParams): __Observable<null> {
    return this.cancelProductionOrderResponse(params).pipe(__map((_r) => _r.body as null));
  }
  resetProductionOrdersResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/ProductionOrder/Reset`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  resetProductionOrders(): __Observable<null> {
    return this.resetProductionOrdersResponse().pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.FinishProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  finishProductionOrderResponse(params: ProductionOrderService.FinishProductionOrderParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/Finish`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.FinishProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  finishProductionOrder(params: ProductionOrderService.FinishProductionOrderParams): __Observable<null> {
    return this.finishProductionOrderResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.EndProductionOrderProductionParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  endProductionOrderProductionResponse(
    params: ProductionOrderService.EndProductionOrderProductionParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/EndProduction`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.EndProductionOrderProductionParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  endProductionOrderProduction(params: ProductionOrderService.EndProductionOrderProductionParams): __Observable<null> {
    return this.endProductionOrderProductionResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.CancelEndProductionParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  cancelEndProductionResponse(params: ProductionOrderService.CancelEndProductionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/CancelEndProduction`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.CancelEndProductionParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  cancelEndProduction(params: ProductionOrderService.CancelEndProductionParams): __Observable<null> {
    return this.cancelEndProductionResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.EndProductionForManualMachineParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  endProductionForManualMachineResponse(
    params: ProductionOrderService.EndProductionForManualMachineParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/EndProductionForManualMachine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.EndProductionForManualMachineParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  endProductionForManualMachine(params: ProductionOrderService.EndProductionForManualMachineParams): __Observable<null> {
    return this.endProductionForManualMachineResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.SaveProductionOrderManualModeSortingValuesParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  saveProductionOrderManualModeSortingValuesResponse(
    params: ProductionOrderService.SaveProductionOrderManualModeSortingValuesParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/SaveManualModeSortingValues`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.SaveProductionOrderManualModeSortingValuesParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  saveProductionOrderManualModeSortingValues(
    params: ProductionOrderService.SaveProductionOrderManualModeSortingValuesParams
  ): __Observable<null> {
    return this.saveProductionOrderManualModeSortingValuesResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.EditProductionOrderDeviationReasonParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  editProductionOrderDeviationReasonResponse(
    params: ProductionOrderService.EditProductionOrderDeviationReasonParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/EditDeviationReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.EditProductionOrderDeviationReasonParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  editProductionOrderDeviationReason(params: ProductionOrderService.EditProductionOrderDeviationReasonParams): __Observable<null> {
    return this.editProductionOrderDeviationReasonResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.SetAsNextProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setAsNextProductionOrderResponse(
    params: ProductionOrderService.SetAsNextProductionOrderParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/SetNext`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.SetAsNextProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setAsNextProductionOrder(params: ProductionOrderService.SetAsNextProductionOrderParams): __Observable<null> {
    return this.setAsNextProductionOrderResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.SetProductionOrderFinishedInfoParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setProductionOrderFinishedInfoResponse(
    params: ProductionOrderService.SetProductionOrderFinishedInfoParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/SetFinishedInfos`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.SetProductionOrderFinishedInfoParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setProductionOrderFinishedInfo(params: ProductionOrderService.SetProductionOrderFinishedInfoParams): __Observable<null> {
    return this.setProductionOrderFinishedInfoResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.SetCurrentRunSubPhaseParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setCurrentRunSubPhaseResponse(params: ProductionOrderService.SetCurrentRunSubPhaseParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/SetCurrentRunSubPhase`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.SetCurrentRunSubPhaseParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setCurrentRunSubPhase(params: ProductionOrderService.SetCurrentRunSubPhaseParams): __Observable<null> {
    return this.setCurrentRunSubPhaseResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.ConfirmProductionOrderKpiParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  confirmProductionOrderKpiResponse(
    params: ProductionOrderService.ConfirmProductionOrderKpiParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/ConfirmKpi`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.ConfirmProductionOrderKpiParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  confirmProductionOrderKpi(params: ProductionOrderService.ConfirmProductionOrderKpiParams): __Observable<null> {
    return this.confirmProductionOrderKpiResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.UnconfirmProductionOrderKpiParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  unconfirmProductionOrderKpiResponse(
    params: ProductionOrderService.UnconfirmProductionOrderKpiParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/UnconfirmKpi`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.UnconfirmProductionOrderKpiParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  unconfirmProductionOrderKpi(params: ProductionOrderService.UnconfirmProductionOrderKpiParams): __Observable<null> {
    return this.unconfirmProductionOrderKpiResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.ReopenProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  reopenProductionOrderResponse(params: ProductionOrderService.ReopenProductionOrderParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/Reopen`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.ReopenProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  reopenProductionOrder(params: ProductionOrderService.ReopenProductionOrderParams): __Observable<null> {
    return this.reopenProductionOrderResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.AddProductionOrderSetupWasteParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  addProductionOrderSetupWasteResponse(
    params: ProductionOrderService.AddProductionOrderSetupWasteParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/AddSetupWasteToProductionOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.AddProductionOrderSetupWasteParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  addProductionOrderSetupWaste(params: ProductionOrderService.AddProductionOrderSetupWasteParams): __Observable<null> {
    return this.addProductionOrderSetupWasteResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.DeleteProductionOrderSetupWasteParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  deleteProductionOrderSetupWasteResponse(
    params: ProductionOrderService.DeleteProductionOrderSetupWasteParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/DeleteSetupWasteFromProductionOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.DeleteProductionOrderSetupWasteParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  deleteProductionOrderSetupWaste(params: ProductionOrderService.DeleteProductionOrderSetupWasteParams): __Observable<null> {
    return this.deleteProductionOrderSetupWasteResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.EditProductionOrderSetupPhaseParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  editProductionOrderSetupPhaseResponse(
    params: ProductionOrderService.EditProductionOrderSetupPhaseParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/EditSetupPhase`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.EditProductionOrderSetupPhaseParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  editProductionOrderSetupPhase(params: ProductionOrderService.EditProductionOrderSetupPhaseParams): __Observable<null> {
    return this.editProductionOrderSetupPhaseResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.SetSetupEndTimeParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setSetupEndTimeResponse(params: ProductionOrderService.SetSetupEndTimeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/SetSetupEndTime`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.SetSetupEndTimeParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setSetupEndTime(params: ProductionOrderService.SetSetupEndTimeParams): __Observable<null> {
    return this.setSetupEndTimeResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.ChangeContainerTargetQuantityParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  changeContainerTargetQuantityResponse(
    params: ProductionOrderService.ChangeContainerTargetQuantityParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/container/targetQuantity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.ChangeContainerTargetQuantityParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  changeContainerTargetQuantity(params: ProductionOrderService.ChangeContainerTargetQuantityParams): __Observable<null> {
    return this.changeContainerTargetQuantityResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.ChangeContainerTargetBobbinQuantityParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  changeContainerTargetBobbinQuantityResponse(
    params: ProductionOrderService.ChangeContainerTargetBobbinQuantityParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/container/targetBobbinQuantity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.ChangeContainerTargetBobbinQuantityParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  changeContainerTargetBobbinQuantity(params: ProductionOrderService.ChangeContainerTargetBobbinQuantityParams): __Observable<null> {
    return this.changeContainerTargetBobbinQuantityResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.PrintLabelForOuterParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  printLabelForOuterResponse(params: ProductionOrderService.PrintLabelForOuterParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/Outer/PrintLabel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.PrintLabelForOuterParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  printLabelForOuter(params: ProductionOrderService.PrintLabelForOuterParams): __Observable<null> {
    return this.printLabelForOuterResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.ChangeProductionOrderOuterInfoParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  changeProductionOrderOuterInfoResponse(
    params: ProductionOrderService.ChangeProductionOrderOuterInfoParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/Outer/Info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.ChangeProductionOrderOuterInfoParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  changeProductionOrderOuterInfo(params: ProductionOrderService.ChangeProductionOrderOuterInfoParams): __Observable<null> {
    return this.changeProductionOrderOuterInfoResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.ChangeOuterQuantityParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  changeOuterQuantityResponse(params: ProductionOrderService.ChangeOuterQuantityParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/Outer/ChangeOuterQuantity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.ChangeOuterQuantityParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  changeOuterQuantity(params: ProductionOrderService.ChangeOuterQuantityParams): __Observable<null> {
    return this.changeOuterQuantityResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.AddProductionOrderWasteAssignmentToOuterParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  addProductionOrderWasteAssignmentToOuterResponse(
    params: ProductionOrderService.AddProductionOrderWasteAssignmentToOuterParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/Outer/AddWasteAssignmentToOuter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.AddProductionOrderWasteAssignmentToOuterParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  addProductionOrderWasteAssignmentToOuter(
    params: ProductionOrderService.AddProductionOrderWasteAssignmentToOuterParams
  ): __Observable<null> {
    return this.addProductionOrderWasteAssignmentToOuterResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.DeleteProductionOrderWasteAssignmentFromOuterParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  deleteProductionOrderWasteAssignmentFromOuterResponse(
    params: ProductionOrderService.DeleteProductionOrderWasteAssignmentFromOuterParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/Outer/DeleteWasteAssignmentFromOuter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.DeleteProductionOrderWasteAssignmentFromOuterParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  deleteProductionOrderWasteAssignmentFromOuter(
    params: ProductionOrderService.DeleteProductionOrderWasteAssignmentFromOuterParams
  ): __Observable<null> {
    return this.deleteProductionOrderWasteAssignmentFromOuterResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProductionOrderService.SplitBillOfMaterialRowParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  splitBillOfMaterialRowResponse(params: ProductionOrderService.SplitBillOfMaterialRowParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/SplitBillOfMaterialRow`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.SplitBillOfMaterialRowParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  splitBillOfMaterialRow(params: ProductionOrderService.SplitBillOfMaterialRowParams): __Observable<null> {
    return this.splitBillOfMaterialRowResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param productionOrderId undefined
   */
  getProductionOrderResponse(productionOrderId: number): __Observable<__StrictHttpResponse<ProductionOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(productionOrderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductionOrder>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getProductionOrder(productionOrderId: number): __Observable<ProductionOrder> {
    return this.getProductionOrderResponse(productionOrderId).pipe(__map((_r) => _r.body as ProductionOrder));
  }

  /**
   * @param externalProductionOrderId undefined
   */
  getProductionOrderInfoByExternalIdResponse(
    externalProductionOrderId: string
  ): __Observable<__StrictHttpResponse<ProductionOrderExternalInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(externalProductionOrderId))}/GetProductionOrderInfoByExternalId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductionOrderExternalInfo>;
      })
    );
  }
  /**
   * @param externalProductionOrderId undefined
   */
  getProductionOrderInfoByExternalId(externalProductionOrderId: string): __Observable<ProductionOrderExternalInfo> {
    return this.getProductionOrderInfoByExternalIdResponse(externalProductionOrderId).pipe(
      __map((_r) => _r.body as ProductionOrderExternalInfo)
    );
  }

  /**
   * @param workCenterId undefined
   */
  getHeaderKpiResponse(workCenterId: number): __Observable<__StrictHttpResponse<HeaderKpi>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/GetHeaderKpi/${encodeURIComponent(String(workCenterId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeaderKpi>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getHeaderKpi(workCenterId: number): __Observable<HeaderKpi> {
    return this.getHeaderKpiResponse(workCenterId).pipe(__map((_r) => _r.body as HeaderKpi));
  }

  /**
   * @param productionOrderId undefined
   */
  getProductionOrderPredecessorInfoResponse(productionOrderId: number): __Observable<__StrictHttpResponse<ProductionOrderPredecessorInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(productionOrderId))}/PredecessorInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductionOrderPredecessorInfo>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getProductionOrderPredecessorInfo(productionOrderId: number): __Observable<ProductionOrderPredecessorInfo> {
    return this.getProductionOrderPredecessorInfoResponse(productionOrderId).pipe(__map((_r) => _r.body as ProductionOrderPredecessorInfo));
  }

  /**
   * @param params The `ProductionOrderService.GetProductionOrderDetailsParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `request`:
   */
  getProductionOrderDetailsResponse(
    params: ProductionOrderService.GetProductionOrderDetailsParams
  ): __Observable<__StrictHttpResponse<Array<ProductionOrderData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.workCenterId))}/GetProductionOrderDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductionOrderData>>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.GetProductionOrderDetailsParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `request`:
   */
  getProductionOrderDetails(params: ProductionOrderService.GetProductionOrderDetailsParams): __Observable<Array<ProductionOrderData>> {
    return this.getProductionOrderDetailsResponse(params).pipe(__map((_r) => _r.body as Array<ProductionOrderData>));
  }

  /**
   * @param productionOrderId undefined
   */
  getBillOfMaterialsResponse(productionOrderId: number): __Observable<__StrictHttpResponse<Array<BillOfMaterial>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(productionOrderId))}/GetBillOfMaterials`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BillOfMaterial>>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getBillOfMaterials(productionOrderId: number): __Observable<Array<BillOfMaterial>> {
    return this.getBillOfMaterialsResponse(productionOrderId).pipe(__map((_r) => _r.body as Array<BillOfMaterial>));
  }

  /**
   * @param params The `ProductionOrderService.GetSetupPhaseStateParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `runId`:
   *
   * - `calledFromApproveReport`:
   */
  getSetupPhaseStateResponse(
    params: ProductionOrderService.GetSetupPhaseStateParams
  ): __Observable<__StrictHttpResponse<ProductionOrderSetupPhaseState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.runId != null) __params = __params.set('runId', params.runId.toString());
    if (params.calledFromApproveReport != null)
      __params = __params.set('calledFromApproveReport', params.calledFromApproveReport.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/GetSetupPhaseState`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductionOrderSetupPhaseState>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.GetSetupPhaseStateParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `runId`:
   *
   * - `calledFromApproveReport`:
   */
  getSetupPhaseState(params: ProductionOrderService.GetSetupPhaseStateParams): __Observable<ProductionOrderSetupPhaseState> {
    return this.getSetupPhaseStateResponse(params).pipe(__map((_r) => _r.body as ProductionOrderSetupPhaseState));
  }

  /**
   * @param productionOrderId undefined
   */
  getManualModeSortingValuesResponse(
    productionOrderId: number
  ): __Observable<__StrictHttpResponse<ProductionOrderManualModeSortingValues>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(productionOrderId))}/GetManualModeSortingValues`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductionOrderManualModeSortingValues>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getManualModeSortingValues(productionOrderId: number): __Observable<ProductionOrderManualModeSortingValues> {
    return this.getManualModeSortingValuesResponse(productionOrderId).pipe(
      __map((_r) => _r.body as ProductionOrderManualModeSortingValues)
    );
  }

  /**
   * @param productionOrderId undefined
   */
  getProductionLoyaltyReasonsResponse(productionOrderId: number): __Observable<__StrictHttpResponse<ProductionOrderProductionLoyalty>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(productionOrderId))}/ProductionLoyaltyReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductionOrderProductionLoyalty>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getProductionLoyaltyReasons(productionOrderId: number): __Observable<ProductionOrderProductionLoyalty> {
    return this.getProductionLoyaltyReasonsResponse(productionOrderId).pipe(__map((_r) => _r.body as ProductionOrderProductionLoyalty));
  }

  /**
   * @param productionOrderId undefined
   */
  getFinishedInfosResponse(productionOrderId: number): __Observable<__StrictHttpResponse<ProductionOrderFinishedInfos>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(productionOrderId))}/GetFinishedInfos`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductionOrderFinishedInfos>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getFinishedInfos(productionOrderId: number): __Observable<ProductionOrderFinishedInfos> {
    return this.getFinishedInfosResponse(productionOrderId).pipe(__map((_r) => _r.body as ProductionOrderFinishedInfos));
  }

  /**
   * @param productionOrderId undefined
   */
  getRunPhasesResponse(productionOrderId: number): __Observable<__StrictHttpResponse<Array<RunPhase>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(productionOrderId))}/RunPhases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RunPhase>>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getRunPhases(productionOrderId: number): __Observable<Array<RunPhase>> {
    return this.getRunPhasesResponse(productionOrderId).pipe(__map((_r) => _r.body as Array<RunPhase>));
  }

  /**
   * @param productionOrderId undefined
   */
  getAllKpisResponse(productionOrderId: number): __Observable<__StrictHttpResponse<Array<KpiValue>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(productionOrderId))}/GetAllKpis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<KpiValue>>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getAllKpis(productionOrderId: number): __Observable<Array<KpiValue>> {
    return this.getAllKpisResponse(productionOrderId).pipe(__map((_r) => _r.body as Array<KpiValue>));
  }

  /**
   * @param params The `ProductionOrderService.GetOuterParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `outerLabelType`:
   */
  getOuterResponse(params: ProductionOrderService.GetOuterParams): __Observable<__StrictHttpResponse<Outer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.outerLabelType != null) __params = __params.set('outerLabelType', params.outerLabelType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/Outer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Outer>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.GetOuterParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `outerLabelType`:
   */
  getOuter(params: ProductionOrderService.GetOuterParams): __Observable<Outer> {
    return this.getOuterResponse(params).pipe(__map((_r) => _r.body as Outer));
  }

  /**
   * @param params The `ProductionOrderService.GetPrintInfoParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `outerLabelType`:
   */
  getPrintInfoResponse(params: ProductionOrderService.GetPrintInfoParams): __Observable<__StrictHttpResponse<OuterPrintInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.outerLabelType != null) __params = __params.set('outerLabelType', params.outerLabelType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/Outer/PrintInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OuterPrintInfo>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.GetPrintInfoParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `outerLabelType`:
   */
  getPrintInfo(params: ProductionOrderService.GetPrintInfoParams): __Observable<OuterPrintInfo> {
    return this.getPrintInfoResponse(params).pipe(__map((_r) => _r.body as OuterPrintInfo));
  }

  /**
   * @param productionOrderId undefined
   */
  getProjectedRunEndResponse(productionOrderId: number): __Observable<__StrictHttpResponse<ProjectedRunEnd>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(productionOrderId))}/GetProjectedRunEnd`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProjectedRunEnd>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getProjectedRunEnd(productionOrderId: number): __Observable<ProjectedRunEnd> {
    return this.getProjectedRunEndResponse(productionOrderId).pipe(__map((_r) => _r.body as ProjectedRunEnd));
  }

  /**
   * @param productionOrderId undefined
   */
  getDefaultTargetContainerQuantityResponse(
    productionOrderId: number
  ): __Observable<__StrictHttpResponse<DefaultTargetContainerQuantityInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ProductionOrder/${encodeURIComponent(String(productionOrderId))}/GetDefaultTargetContainerQuantity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DefaultTargetContainerQuantityInfo>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getDefaultTargetContainerQuantity(productionOrderId: number): __Observable<DefaultTargetContainerQuantityInfo> {
    return this.getDefaultTargetContainerQuantityResponse(productionOrderId).pipe(
      __map((_r) => _r.body as DefaultTargetContainerQuantityInfo)
    );
  }

  /**
   * @param params The `ProductionOrderService.SplitBillOfMaterialDataParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `billOfMaterialId`:
   */
  splitBillOfMaterialDataResponse(
    params: ProductionOrderService.SplitBillOfMaterialDataParams
  ): __Observable<__StrictHttpResponse<SplitBillOfMaterialData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/ProductionOrder/${encodeURIComponent(String(params.productionOrderId))}/billOfMaterials/${encodeURIComponent(
          String(params.billOfMaterialId)
        )}/SplitBillOfMaterialData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SplitBillOfMaterialData>;
      })
    );
  }
  /**
   * @param params The `ProductionOrderService.SplitBillOfMaterialDataParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `billOfMaterialId`:
   */
  splitBillOfMaterialData(params: ProductionOrderService.SplitBillOfMaterialDataParams): __Observable<SplitBillOfMaterialData> {
    return this.splitBillOfMaterialDataResponse(params).pipe(__map((_r) => _r.body as SplitBillOfMaterialData));
  }
}

module ProductionOrderService {
  /**
   * Parameters for CheckProductionOrder
   */
  export interface CheckProductionOrderParams {
    productionOrderId: number;
    entry: CheckProductionOrderEntry;
  }

  /**
   * Parameters for UncheckProductionOrder
   */
  export interface UncheckProductionOrderParams {
    productionOrderId: number;
    entry: UncheckProductionOrderEntry;
  }

  /**
   * Parameters for PresetupProductionOrder
   */
  export interface PresetupProductionOrderParams {
    productionOrderId: number;
    entry: PresetupProductionOrderEntry;
  }

  /**
   * Parameters for CancelProductionOrderPresetup
   */
  export interface CancelProductionOrderPresetupParams {
    productionOrderId: number;
    entry: CancelProductionOrderPresetupEntry;
  }

  /**
   * Parameters for StartProductionOrder
   */
  export interface StartProductionOrderParams {
    productionOrderId: number;
    entry: StartProductionOrderEntry;
  }

  /**
   * Parameters for StartProductionOrderManualMachine
   */
  export interface StartProductionOrderManualMachineParams {
    productionOrderId: number;
    entry: StartManualProductionOrderEntry;
  }

  /**
   * Parameters for StartProductionOrderWithAutomaticPeriodSelection
   */
  export interface StartProductionOrderWithAutomaticPeriodSelectionParams {
    productionOrderId: number;
    entry: StartProductionOrderEntry;
  }

  /**
   * Parameters for CancelProductionOrder
   */
  export interface CancelProductionOrderParams {
    productionOrderId: number;
    entry: CancelProductionOrderEntry;
  }

  /**
   * Parameters for FinishProductionOrder
   */
  export interface FinishProductionOrderParams {
    productionOrderId: number;
    entry: FinishProductionOrderEntry;
  }

  /**
   * Parameters for EndProductionOrderProduction
   */
  export interface EndProductionOrderProductionParams {
    productionOrderId: number;
    entry: EndProductionOrderProductionEntry;
  }

  /**
   * Parameters for CancelEndProduction
   */
  export interface CancelEndProductionParams {
    productionOrderId: number;
    entry: CancelEndProductionEntry;
  }

  /**
   * Parameters for EndProductionForManualMachine
   */
  export interface EndProductionForManualMachineParams {
    productionOrderId: number;
    entry: FinishProductionOrderForManualMachineEntry;
  }

  /**
   * Parameters for SaveProductionOrderManualModeSortingValues
   */
  export interface SaveProductionOrderManualModeSortingValuesParams {
    productionOrderId: number;
    entry: ProductionOrderManualModeSortingValuesEntry;
  }

  /**
   * Parameters for EditProductionOrderDeviationReason
   */
  export interface EditProductionOrderDeviationReasonParams {
    productionOrderId: number;
    entry: ProductionOrderDeviationEntry;
  }

  /**
   * Parameters for SetAsNextProductionOrder
   */
  export interface SetAsNextProductionOrderParams {
    productionOrderId: number;
    entry: ProductionOrderDeviationEntry;
  }

  /**
   * Parameters for SetProductionOrderFinishedInfo
   */
  export interface SetProductionOrderFinishedInfoParams {
    productionOrderId: number;
    entry: SetProductionOrderFinishInfoEntry;
  }

  /**
   * Parameters for SetCurrentRunSubPhase
   */
  export interface SetCurrentRunSubPhaseParams {
    productionOrderId: number;
    entry: SetCurrentRunPhaseEntry;
  }

  /**
   * Parameters for ConfirmProductionOrderKpi
   */
  export interface ConfirmProductionOrderKpiParams {
    productionOrderId: number;
    entry: ConfirmProductionOrderKpiEntry;
  }

  /**
   * Parameters for UnconfirmProductionOrderKpi
   */
  export interface UnconfirmProductionOrderKpiParams {
    productionOrderId: number;
    entry: UnconfirmProductionOrderKpiEntry;
  }

  /**
   * Parameters for ReopenProductionOrder
   */
  export interface ReopenProductionOrderParams {
    productionOrderId: number;
    entry: ReopenProductionOrderEntry;
  }

  /**
   * Parameters for AddProductionOrderSetupWaste
   */
  export interface AddProductionOrderSetupWasteParams {
    productionOrderId: number;
    entry: AddProductionOrderSetupWasteEntry;
  }

  /**
   * Parameters for DeleteProductionOrderSetupWaste
   */
  export interface DeleteProductionOrderSetupWasteParams {
    productionOrderId: number;
    entry: DeleteProductionOrderSetupWasteEntry;
  }

  /**
   * Parameters for EditProductionOrderSetupPhase
   */
  export interface EditProductionOrderSetupPhaseParams {
    productionOrderId: number;
    entry: EditProductionOrderSetupPhaseEntry;
  }

  /**
   * Parameters for SetSetupEndTime
   */
  export interface SetSetupEndTimeParams {
    productionOrderId: number;
    entry: SetSetupEndTimeEntry;
  }

  /**
   * Parameters for ChangeContainerTargetQuantity
   */
  export interface ChangeContainerTargetQuantityParams {
    productionOrderId: number;
    entry: ChangeContainerTargetQuantityEntry;
  }

  /**
   * Parameters for ChangeContainerTargetBobbinQuantity
   */
  export interface ChangeContainerTargetBobbinQuantityParams {
    productionOrderId: number;
    entry: ChangeContainerTargetBobbinQuantityEntry;
  }

  /**
   * Parameters for PrintLabelForOuter
   */
  export interface PrintLabelForOuterParams {
    productionOrderId: number;
    entry: PrintLabelForOuterEntry;
  }

  /**
   * Parameters for ChangeProductionOrderOuterInfo
   */
  export interface ChangeProductionOrderOuterInfoParams {
    productionOrderId: number;
    entry: ChangeProductionOrderOuterInfoEntry;
  }

  /**
   * Parameters for ChangeOuterQuantity
   */
  export interface ChangeOuterQuantityParams {
    productionOrderId: number;
    entry: ChangeOuterQuantityEntry;
  }

  /**
   * Parameters for AddProductionOrderWasteAssignmentToOuter
   */
  export interface AddProductionOrderWasteAssignmentToOuterParams {
    productionOrderId: number;
    entry: AddProductionOrderWasteAssignmentToOuterEntry;
  }

  /**
   * Parameters for DeleteProductionOrderWasteAssignmentFromOuter
   */
  export interface DeleteProductionOrderWasteAssignmentFromOuterParams {
    productionOrderId: number;
    entry: DeleteProductionOrderWasteAssignmentFromOuterEntry;
  }

  /**
   * Parameters for SplitBillOfMaterialRow
   */
  export interface SplitBillOfMaterialRowParams {
    productionOrderId: number;
    entry: SplitBillOfMaterialRowEntry;
  }

  /**
   * Parameters for GetProductionOrderDetails
   */
  export interface GetProductionOrderDetailsParams {
    workCenterId: number;
    request: GetProductionOrdersForTimePeriodEntry;
  }

  /**
   * Parameters for GetSetupPhaseState
   */
  export interface GetSetupPhaseStateParams {
    productionOrderId: number;
    runId?: null | number;
    calledFromApproveReport?: boolean;
  }

  /**
   * Parameters for GetOuter
   */
  export interface GetOuterParams {
    productionOrderId: number;
    outerLabelType?: 'Outer' | 'Core';
  }

  /**
   * Parameters for GetPrintInfo
   */
  export interface GetPrintInfoParams {
    productionOrderId: number;
    outerLabelType?: 'Outer' | 'Core';
  }

  /**
   * Parameters for SplitBillOfMaterialData
   */
  export interface SplitBillOfMaterialDataParams {
    productionOrderId: number;
    billOfMaterialId: number;
  }
}

export { ProductionOrderService };
